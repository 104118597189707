import React from "react";
// import { graphql, useStaticQuery } from "gatsby";

const Terms = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     allGoogleDocs(
  //       filter: { id: { eq: "74cfa426-b7b9-5353-9ac3-10a41086a6dd" } }
  //     ) {
  //       edges {
  //         node {
  //           childMarkdownRemark {
  //             html
  //           }
  //           id
  //         }
  //       }
  //     }
  //   }
  // `);

  return (
    <>
      <div class="content-drive content-terms"><h4>Allgemein</h4>
<p>Alle Produkte die <strong>citylove.community</strong> online stellt, stellen kein rechtlich bindendes Angebot, sondern eine Aufforderung zur Bestellung dar. Irrtümer vorbehalten.</p>
<p>Durch Anklicken des Button [Kaufen] geben Sie eine verbindliche Bestellung der im Warenkorb gelisteten Produkte ab.</p>
<p>Alle Gutscheine, die von <strong>citylove.community</strong> angeboten werden sind ausschließlich nur über <strong>citylove.community</strong> erhältlich – angebotene Preise haben nur bei <strong>citylove.community</strong> ihre Gültigkeit. Bei Wertschecks sind keine Barablöse und kein Retourgeld möglich.</p>
<h4>I. Persönliche Voraussetzungen zur Benutzung</h4>
<p>Die Benutzung des Service von <strong>citylove.community</strong> ist nur uneingeschränkt geschäftsfähigen, zumindest 18-Jahre alten, natürlichen Personen gestattet. Juristische Personen können nur durch ihre vertretungsbefugten Organe handeln. <strong>citylove.community</strong> behält sich vor, Personen, denen uneingeschränkte Geschäftsfähigkeit in Zweifel steht, von der Benutzung des Service auszuschließen.</p>
<h5>Registrierung/Bestellung</h5>
<p>Benutzer sind verpflichtet, bei der Registrierung oder Bestellung vollständige und korrekte Angaben zur Person zu machen. Bei Änderungen (Namensänderung durch Eheschließung, Adressänderung, neue Telefonnummer etc.) sind die Angaben umgehend per Mail bekannt zu geben.</p>
<p>Die Registrierung/Bestellung hat mit der privaten Adresse (kein Postfach) zu erfolgen. Unvollständige und falsche Angaben können zu einer Sperre führen.</p>
<h4>II. Ablehnung und Ausschluss von Benutzern</h4>
<p><strong>citylove.community</strong> hat neben der freien Entscheidung über die Zulassung eines Benutzers und die des „Hausrechts“, das Recht, Benutzer bei einem Verstoß gegen die AGB, gegen Gesetze, die guten Sitten oder aus sonstigen Gründen, insbesondere aber nicht nur wegen Zweifeln über die unbeschränkte Geschäftsfähigkeit des Benutzers, nach ihrem Dafürhalten auszuschließen oder einzelne Käufer abzulehnen, bzw. Bestellungen über <strong>citylove.community</strong> nicht anzunehmen.</p>
<h5>Verwarnung und Ausschluss von Mitgliedern</h5>
<p>Um für den sach- und vertragsgerechten Ablauf der Geschäfte auf <strong>citylove.community</strong> zu sorgen, gelten die im Punkt 1 angeführten Grundsätze. Erlangt <strong>citylove.community</strong>  über Verstöße gegen diese Grundsätze Kenntnis und liegen konkrete Anhaltspunkte vor, trifft <strong>citylove.community</strong> unter Berücksichtigung der berechtigten Interessen des Nutzers die nachfolgend beschriebenen Maßnahmen:</p>
<p>Beschreibung des Vorfalles – Maßnahmen durch <strong>citylove.community</strong></p>
<p>Angabe falscher Registrierungsdaten (Name, Telefonnummer, Adresse) – Ausschluss und Sperre des Benutzers</p>
<p>Der Benutzer ist unter 18 Jahre alt – Ausschluss und Sperre des Benutzers</p>
<p>Der Käufer verweigert ohne sachlichen Grund die Abnahme eines gekauften Gegenstandes – Siehe III.</p>
<p>Verletzung anderer Bestimmungen dieser AGB oder anwendbarer gesetzlicher Bestimmungen – Verwarnung oder Ausschluss und Sperre des Benutzers</p>
<p>Eine Verwarnung, bzw. ein Ausschluss eines Benutzers kann jederzeit auch erfolgen, wenn <strong>citylove.community</strong> unter Berücksichtigung der berechtigten Interessen des Nutzers, ein sonstiges berechtigtes Interesse an einer Verwarnung, bzw. an einem Ausschluss eines Benutzers hat.</p>
<p>Offene Rechnungsbeträge sowie sonstige Gebühren zuzüglich allfälliger Mahnspesen sind auch bei einem Ausschluss weiterhin geschuldet.</p>
<h4>III. Rücktritt vom Verkauf</h4>
<p>a) Storno, Stornogebühr, Rücktrittsrecht: Jeder Kunde bestätigt mehrmals im Laufe einer Bestellung den Artikel ganz sicher kaufen zu wollen und alle Bedingungen von <strong>citylove.community</strong> gelesen zu haben. Sie haben laut Konsumentenschutz ein Rücktrittsrecht von 7 Werktagen, außer bei kurzem Ablaufdatum (siehe nächster Punkt). Danach ist keine Stornierung sowie Rücknahme oder Umtausch von Produkten oder Gutscheinen möglich. Für Gutscheine gilt generell kein Umtauschrecht.</p>
<p>Erfolgt eine Stornierung bis spätestens 3 Stunden nach Bestellung fällt keine Stornogebühr an.</p>
<p>Bei einer Stornierung nach erfolgtem Versand werden die Versandkosten zuzüglich der Abwicklungsspesen von 3,90 Euro dem Kunden verrechnet. Sollte der versendete Brief, aufgrund falscher oder unzureichender Adressangabe, wieder retour gesendet werden und nicht rechtzeitig beim Kunden ankommen, übernimmt der Vertragspartner keinerlei Haftung.</p>
<p>Eine Nichtannahme eines erworbenen Artikels hat eine automatische Sperre bei <strong>citylove.community</strong> zur Folge sowie die Einbringung des offenen Betrages, wenn nötig bis zur Klagseinbringung.</p>
<p>Bei einer Stornierung nach erfolgtem Versand werden die Versandkosten zuzüglich der Abwicklungspesen von 3,50 Euro dem Kunden verrechnet.</p>
<p>Stornierungen sind schriftlich einzubringen und nur gültig mit der schriftlichen Bestätigung von <strong>citylove.community</strong>.</p>
<p>b) Kein Rücktrittsrecht bei kurzem Ablaufdatum</p>
<p>Das Rücktrittsrecht gilt nur bei Gutscheinen deren Ablaufdatum beim Kaufdatum nicht weniger als 30 Tage entfernt ist. Insbesondere bei Konzert- oder Eventtickets, wo Restposten oft wenige Tage vor dem Termin sehr günstig ausgegeben werden entfällt ein Rücktrittsrecht des Käufers (siehe KschG, §5f)</p>
<p>KschG § 5f Verweis 7: Der Verbraucher hat kein Rücktrittsrecht bei Verträgen über Hauslieferungen oder Freizeit-Dienstleistungen lt. § 5c Abs. 4 Z 1 und 2.</p>
<p>KschG § 5c Abs. 4 Z 1 über die Lieferung von Lebensmitteln, Getränken oder sonstigen Haushaltsgegenständen des täglichen Bedarfs, die am Wohnsitz, am Aufenthaltsort oder am Arbeitsplatz des Verbrauchers von Unternehmern im Rahmen häufiger und regelmäßiger Fahrten geliefert werden (Hauslieferungen), sowie</p>
<p>KschG § 5c Abs. 4 Z 2 über Dienstleistungen in den Bereichen Unterbringung, Beförderung, Lieferung von Speisen und Getränken sowie Freizeitgestaltung, wenn sich der Unternehmer bei Vertragsabschluss verpflichtet, die Dienstleistungen zu einem bestimmten Zeitpunkt oder innerhalb eines genau angegebenen Zeitraums zu erbringen (Freizeit-Dienstleistungen).</p>
<h4>IV. Rücknahme oder Austausch von Gutscheinen</h4>
<p>Ist nur möglich mit der dazugehörigen Bestellbestätigung bzw. mit der Rechnung. Ohne einer dieser Bestätigungen können keine Gutscheine zurückgenommen oder ausgetauscht werden.</p>
<h4>V. Preise und Versandkosten</h4>
<p>a) Die auf den Produktseiten genannten Preise enthalten die gesetzliche Mehrwertsteuer und sonstige Preisbestandteile.</p>
<p>b) Zusätzlich zu den angegebenen Preisen berechnen wir für Versand pauschal 3,90 Euro pro Bestellung (12,- Euro bei internationalem Postversand). Die Versandkosten werden Ihnen im Warenkorbsystem nochmals deutlich mitgeteilt.</p>
<h4>VI. Lieferung – Zahlung</h4>
<p>a) Gutscheinversand: Der Gutscheinversand erfolgt prinzipiell digital. Sollte der Postweg gewählt werden, dann ausschließlich mit eingeschriebener Postsendung. Nur in dieser Lieferform übernimmt die Post bei Verlust die Haftung für das Produkt!</p>
<p>b) Sammellieferung: Um Versandkosten zu sparen ist es auch möglich mehrere Bestellungen zusammenkommen zu lassen und gemeinsam schicken zu lassen. Eine Zuwartung für mehrere Bestellungen ist für einen Zeitraum von 10 Werktagen und nur mit vorherigem E-Mail-Kontakt möglich. Danach wird die Sendung automatisch in der gewählten Versandart versandt.</p>
<p>c) Zahlung: Die Zahlung erfolgt über die angegebenen Zahlungsmöglichkeiten.</p>
<h4>VII. Gültigkeit der Angebote und Bestellungen</h4>
<p>Angebote bzw. Bestellungen bei <strong>citylove.community</strong> gelten mit Vorbehalt, d.h. sie können nur erfüllt werden solange der Vorrat reicht.</p>
<h4>VIII. Gültigkeit von Gutscheinen</h4>
<p>Die auf Gutscheinen angeführte Gültigkeit ist bindend und der Gutschein verfällt nach Ablauf des Gültigkeitsdatums. Eine Verlängerung ist nicht möglich. Der Gutschein verliert auch seine Gültigkeit wenn der Kunde irgendwelche Veränderung am Gutschein durchführt (zusätzliches Beschreiben, Kopieren, Beschädigung). Eine Firma ist nur verpflichtet Original-Gutscheine anzunehmen, keine Kopien.</p>
<p>Verlust von Gutscheinen: Ein Gutschein ist ein Originaldokument, weder der Vertragspartner noch <strong>citylove.community</strong> können Haftung bei Verlust übernehmen.</p>
<h4>IX. Rücknahme oder Austausch von Gutscheinen</h4>
<p>Ist nur in besonderen Fällen möglich und dann auch nur mit der dazugehörigen Bestellbestätigung bzw. mit der Rechnung. Ohne einer dieser Bestätigungen können und dürfen keine Gutscheine zurückgenommen oder ausgetauscht werden.</p>
<p>KEIN UMTAUSCHRECHT bei Erwerb von Tickets oder Gutscheinen mit BONUS.</p>
<h4>X. Rechtliche Haftung für Leistungen</h4>
<p><strong>citylove.community</strong> tritt mit dem Gutscheinverkauf als Vermittler der Leistungen zwischen dem Käufer und der für die Gutscheinleistung verantwortlichen Firma auf.</p>
<p>Bei Reisegutscheinen ist <strong>citylove.community</strong> nicht der Veranstalter sondern vermittelt ebenfalls nur den Gutschein zwischen Kunden und Reiseveranstalter bzw. Hotel.</p>
<p>Die Erfüllungspflicht liegt zur Gänze bei der Vertragsfirma.</p>
<p>Kein Ersatzanspruch bei Insolvenz oder Auflösung des Vertragspartners nach dem Erwerbsdatum der Gutscheine.</p>
<p>Alle Eigentumsrechte vorbehalten.</p>
<h4>XI. Gültigkeit von Bonuspunkten</h4>
<p>Bonuspunkte haben so lange Gültigkeit, so lange eine Vorteilsclub-Mitgliedschaft besteht. Erlischt die Mitgliedschaft, verfallen die gesammelten Bonuspunkte unwiderruflich.</p>
<p>Keine Barablöse für Bonuspunkte.</p>
<h4>XII. Kein Wiederverkauf an Dritte</h4>
<p>Gutscheine und Tickets sind nicht übertragbar oder wieder verkaufbar!</p>
<p>Wir weisen ausdrücklich darauf hin, dass bei <strong>www.citylove.community.at</strong> erworbene Gutscheine und Tickets nicht auf anderen Onlineplattformen oder unter der Hand weiterverkauft werden dürfen!</p>
<h4>XIII. Preisreklamationen oder Preisnachlässe</h4>
<p>Da sich die Preise aufgrund von Nachfrage und Angebot ändern, sind nach einer getätigten Bestellung nachträgliche Preisänderungen bzw. Preisvergünstigungen nicht möglich und strengstens untersagt.</p>
<h4>XIV. Verwendung von Bildern und Texten</h4>
<p>Damit wir unsere Dienste betreiben und bereitstellen können sowie zu bewerben, gewährt uns das Unternehmen eine weltweite, nicht-exklusive, gebührenfreie, unterlizenzierbare und übertragbare Lizenz zur Nutzung, Reproduktion, Verbreitung, Erstellung abgeleiteter Werke, Darstellung und Aufführung der Informationen (einschließlich der Inhalte), die es uns per Mail zukommen lässt und auf bzw. über unsere/n Dienste/n hochlädst, übermittelt, speichert, sendet oder empfängt.</p>
<h4>XV. Datenschutz</h4>
<p>Ich bin damit einverstanden, dass meine Daten zum Zweck der Abwicklung der</p>
<p>Gutscheinerstellung von citylove.community bzw. Network ventures verwendet und</p>
<p>verarbeitet werden. Insbesondere erfassen die Dienste GiftUp und Stripe Benutzerdaten wie Name, Adresse, und Kreditkarteninformationen. Abgesehen davon werden keinerlei Daten an Dritte weitergegeben.</p></div>
    </>
  );
};

export default Terms;
