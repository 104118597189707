import React from "react";
import Layout from "../components/layout";
import withUIKit from "../withUIKit";
import DisclaimerHeader from "../components/Custom/Headers/DisclaimerHeader";
import { LocalizedButton } from "../components/language/LocalizedButton";
import Navbar from "../components/Custom/Navbar";
import Terms from "src/components/Custom/GDriveContent/Terms.js";
// reactstrap components
import { Container } from "reactstrap";

const Disclaimer = props => {

  return (
    <Layout {...props} >
      <Navbar {...props} />
      <DisclaimerHeader />
      <div className="wrapper">
        
        {/* <div className="section text-center landing-section">
          <h2 className="mb-5 text-center">Haftungsausschluss</h2>
          <VerticalTabs data={stepByStepData} />
        </div>

        <hr /> */}

        <div className="section">
          <Container>
            <Terms></Terms>
          </Container>
        </div>

        <hr />

        <div className="section text-center landing-section">
         {/* <LocalizedButton
              className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
              color="danger"
              language={props.language}
              to="/"
            >
              Gutscheine finden
            </LocalizedButton> */}
            {/* <LocalizedButton
              className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
              color="danger"
              language={props.language}
              to="/how-to"
            >
              So funktioniert's
            </LocalizedButton> */}
            <LocalizedButton
              className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
              color="danger"
              language={props.language}
              to="/joinin"
            >
              Partner werden
            </LocalizedButton>
            <LocalizedButton
              className="btn-primary btn-round ml-2 mr-2 mt-2 mb-2"
              color="danger"
              language={props.language}
              to="/about"
            >
              Über
            </LocalizedButton>
        </div>
      </div>
    </Layout>
  );
};

export default withUIKit(Disclaimer);
